import React from 'react'
import './Footer.scss';

const Footer = () => {
  return (
    <>
        <footer>
            Email: <a className='' href="mailto:info@crossingweb.com.ar">info@crossingweb.com.ar</a> - Tel:
            <a className='' href="tel:+5491154628750">+54 9 11 54628750</a>
        </footer>
    </>
  )
}
 
export default Footer