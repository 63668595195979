import logo from '../../assets/logo.svg';
import Footer from '../../components/Footer/Footer';
import './Home.scss';

function Home() {
  return (
    <>
      <main>
        <div>
          <img src={logo} className="" alt="crossing" />
          <h1>crossingweb</h1>
          <i></i>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Home;
